import sesDigitalClaimsAxios from "./SesDigitalClaimsAxios";

const claimEndpoint = "/claims";

function addClaim(claim, files, reports, token) {
  const formData = new FormData();

  const propertiesBlob = new Blob([JSON.stringify(claim)], {
    type: "application/json",
  });
  propertiesBlob.fileName = "properties";
  formData.append("properties", propertiesBlob);

  files.forEach((file) => formData.append("files", file));
  reports.forEach((report) => formData.append("reports", report));

  formData.append("token", token);

  return sesDigitalClaimsAxios
    .post(`${claimEndpoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
}

export { addClaim };

<template>
  <v-container>
    <!-- error handler -->
    <v-row v-if="hasInvalidFileError" justify="center">
      <v-col
        cols="12"
        md="6"
        sm="8"
        style="z-index: 1; position: fixed; top: 10px"
      >
        <v-alert
          v-model="hasInvalidFileError"
          transition="fade-transition"
          icon="mdi-cancel"
          color="error"
          dismissible
          elevation="3"
          type="error"
        >
          {{ $t("fileUpload.validFileFormats") }}
        </v-alert>
      </v-col>
    </v-row>

    <v-form ref="form">
      <!-- Header -->
      <template>
        <div
          class="red--text text-right text-caption"
          v-text="$t('equipmentAndLoss.instructions')"
        />
        <div
          class="text-h4 font-weight-bold"
          v-text="$t('equipmentAndLoss.title')"
        />
        <v-alert type="info" class="mt-4">
          {{ $t("equipmentAndLoss.information") }}
        </v-alert>
      </template>

      <!-- Equipment Table -->
      <template>
        <v-data-table
          ref="table"
          :headers="headers"
          :items="localFormData.equipment"
          :class="isRowOpen ? 'backgroundRows elevation-1' : 'elevation-1'"
          :item-class="row_classes"
          hide-default-footer
          disable-sort
          disable-pagination
          @click:row="openRow"
        >
          <template #[`item.type`]="props">
            <v-text-field
              v-if="isRowOpen && props.item.row_number === openRowNumber"
              id="equipmentType"
              ref="type-field"
              v-model="editeditem.type"
              maxlength="254"
              hide-details="auto"
              outlined
              dense
            />
            <span v-else>{{ props.item.type }}</span>
          </template>
          <template #[`item.make`]="props">
            <v-text-field
              v-if="isRowOpen && props.item.row_number === openRowNumber"
              id="equipmentMake"
              ref="make-field"
              v-model="editeditem.make"
              maxlength="254"
              hide-details="auto"
              outlined
              dense
            />
            <span v-else>{{ props.item.make }}</span>
          </template>
          <template #[`item.model`]="props">
            <v-text-field
              v-if="isRowOpen && props.item.row_number === openRowNumber"
              id="equipmentModel"
              ref="model-field"
              v-model="editeditem.model"
              maxlength="254"
              hide-details="auto"
              outlined
              dense
            />
            <span v-else>{{ props.item.model }}</span>
          </template>
          <template #[`item.serialNumber`]="props">
            <v-text-field
              v-if="isRowOpen && props.item.row_number === openRowNumber"
              id="equipmentNumber"
              ref="serial-field"
              v-model="editeditem.serialNumber"
              maxlength="50"
              hide-details="auto"
              outlined
              dense
            />
            <span v-else>{{ props.item.serialNumber }}</span>
          </template>
          <template #[`item.year`]="props">
            <v-text-field
              v-if="isRowOpen && props.item.row_number === openRowNumber"
              id="equipmentYear"
              ref="year-field"
              v-model="editeditem.year"
              maxlength="4"
              hide-details="auto"
              outlined
              dense
              @keypress="filterEquipmentYear"
            />
            <span v-else>{{ props.item.year }}</span>
          </template>
          <template #[`body.append`]="{ headers }">
            <tr id="addEquipment" @click="addRow">
              <td :colspan="headers.length">
                <v-icon
                  :style="
                    isRowOpen
                      ? { color: 'rgba(162, 162, 162, 0.25)' }
                      : { color: 'rgb(0, 55, 100)' }
                  "
                  >mdi-plus-circle</v-icon
                >
                {{ $t("equipmentAndLoss.addEquipment") }}
              </td>
            </tr>
          </template>
          <template #no-data>
            <div></div>
          </template>
        </v-data-table>
        <v-row
          v-if="isRowOpen"
          class="controlActions py-1"
          :style="{ top: controlActionsLocation + 'px' }"
          justify="center"
        >
          <v-icon
            id="saveEquipment"
            dense
            class="icons mx-2"
            style="font-size: 1.8rem"
            @click="saveChanges"
          >
            mdi-check-bold
          </v-icon>
          <v-icon
            id="cancelEquipment"
            dense
            class="icons"
            style="font-size: 1.8rem"
            :disabled="addNewRow"
            @click="cancelChanges"
          >
            mdi-close-thick
          </v-icon>
          <v-icon
            id="removeEquipment"
            dense
            class="icons mx-2"
            style="font-size: 1.8rem"
            @click="deleteRow"
          >
            mdi-trash-can-outline
          </v-icon>
        </v-row>
      </template>

      <!-- Loss Details -->
      <template>
        <h2 class="mt-8">
          {{ $t("equipmentAndLoss.lossDetails") }}
        </h2>
        <label for="lossOccurrenceDate">
          {{ $t("equipmentAndLoss.lossOccurrenceDate") }}
          <span class="red--text"><strong> *</strong></span>
        </label>
        <v-menu
          id="lossOccurrenceDate"
          v-model="isCalendarOpen"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              id="lossDate"
              v-model="occurDateFormatted"
              placeholder="__ /__ /____"
              outlined
              dense
              append-icon="mdi-calendar-month"
              :rules="[rules.required, rules.notFutureDate]"
              v-bind="attrs"
              type="text"
              v-on="on"
              @click:append="isCalendarOpen = true"
              @blur="lossDateLeft($event)"
              @keypress="filterDateInput($event)"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="occurDateFormattedPicker"
            show-current
            :max="todayDate"
            @input="isCalendarOpen = false"
          ></v-date-picker>
        </v-menu>
      </template>

      <!-- Type of Damage -->
      <template>
        {{ $t("equipmentAndLoss.typeOfDamage") }}
        <v-item-group v-model="localFormData.lossDetails[0].damageTypeCode">
          <v-container>
            <v-row justify="start">
              <v-col cols="auto">
                <v-item v-slot="{ active, toggle }" :value="DamageType.FIRE">
                  <v-card
                    :id="`${DamageType.FIRE}-damage-type`"
                    class="d-flex align-center text-center"
                    min-width="184"
                    min-height="200"
                    outlined
                    @click="toggle"
                  >
                    <v-card-text>
                      <div class="d-flex align-center text-center">
                        <v-img
                          contain
                          height="90"
                          width="90"
                          src="../assets/fire.png"
                        ></v-img>
                      </div>
                      <div>
                        <h3>
                          {{ $t("equipmentAndLoss.fire") }}
                        </h3>
                      </div>

                      <div v-if="active" class="display-3 flex-grow-1">
                        <v-icon x-large>mdi-checkbox-marked-outline</v-icon>
                      </div>

                      <div v-if="!active" class="display-3 flex-grow-1">
                        <v-icon x-large>mdi-checkbox-blank-outline</v-icon>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-item>
              </v-col>
              <v-col cols="auto">
                <v-item v-slot="{ active, toggle }" :value="DamageType.THEFT">
                  <v-card
                    :id="`${DamageType.THEFT}-damage-type`"
                    class="d-flex align-center text-center"
                    outlined
                    min-width="184"
                    min-height="200"
                    @click="toggle"
                  >
                    <v-card-text>
                      <div class="d-flex align-center text-center">
                        <v-img
                          contain
                          height="90"
                          width="90"
                          src="../assets/theft.png"
                        ></v-img>
                      </div>
                      <div>
                        <h3>
                          {{ $t("equipmentAndLoss.theftOfRobbery") }}
                        </h3>
                      </div>

                      <div v-if="active" class="display-3 flex-grow-1">
                        <v-icon x-large>mdi-checkbox-marked-outline</v-icon>
                      </div>

                      <div v-if="!active" class="display-3 flex-grow-1">
                        <v-icon x-large>mdi-checkbox-blank-outline</v-icon>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-item>
              </v-col>
              <v-col cols="auto">
                <v-item v-slot="{ active, toggle }" :value="DamageType.WATER">
                  <v-card
                    :id="`${DamageType.WATER}-damage-type`"
                    class="d-flex align-center text-center"
                    outlined
                    min-width="184"
                    min-height="200"
                    @click="toggle"
                  >
                    <v-card-text>
                      <div class="d-flex align-center text-center">
                        <v-img
                          contain
                          height="90"
                          width="90"
                          src="../assets/water.png"
                        ></v-img>
                      </div>
                      <div>
                        <h3>
                          {{ $t("equipmentAndLoss.water") }}
                        </h3>
                      </div>

                      <div v-if="active" class="display-3 flex-grow-1">
                        <v-icon x-large>mdi-checkbox-marked-outline</v-icon>
                      </div>

                      <div v-if="!active" class="display-3 flex-grow-1">
                        <v-icon x-large>mdi-checkbox-blank-outline</v-icon>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-item>
              </v-col>
              <v-col cols="auto">
                <v-item
                  v-slot="{ active, toggle }"
                  :value="DamageType.ACCIDENT"
                >
                  <v-card
                    :id="`${DamageType.ACCIDENT}-damage-type`"
                    class="d-flex align-center text-center"
                    outlined
                    min-width="184"
                    min-height="200"
                    @click="toggle"
                  >
                    <v-card-text>
                      <div class="d-flex align-center text-center">
                        <v-img
                          contain
                          height="90"
                          width="90"
                          src="../assets/accident.png"
                        ></v-img>
                      </div>
                      <div>
                        <h3>
                          {{ $t("equipmentAndLoss.accident") }}
                        </h3>
                      </div>

                      <div v-if="active" class="display-3 flex-grow-1">
                        <v-icon x-large>mdi-checkbox-marked-outline</v-icon>
                      </div>

                      <div v-if="!active" class="display-3 flex-grow-1">
                        <v-icon x-large>mdi-checkbox-blank-outline</v-icon>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-item>
              </v-col>
              <v-col cols="auto">
                <v-item v-slot="{ active, toggle }" :value="DamageType.OTHER">
                  <v-card
                    :id="`${DamageType.OTHER}-damage-type`"
                    class="d-flex align-center text-center"
                    outlined
                    min-width="184"
                    min-height="200"
                    @click="toggle"
                  >
                    <v-card-text>
                      <div class="d-flex align-center text-center">
                        <v-img
                          contain
                          height="90"
                          width="90"
                          src="../assets/others.png"
                        ></v-img>
                      </div>
                      <div>
                        <h3>
                          {{ $t("equipmentAndLoss.other") }}
                        </h3>
                      </div>

                      <div v-if="active" class="display-3 flex-grow-1">
                        <v-icon x-large>mdi-checkbox-marked-outline</v-icon>
                      </div>

                      <div v-if="!active" class="display-3 flex-grow-1">
                        <v-icon x-large>mdi-checkbox-blank-outline</v-icon>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
        <v-expand-transition>
          <div
            v-show="
              localFormData.lossDetails[0].damageTypeCode === DamageType.FIRE
            "
            class="fire"
          >
            <label for="nameOfFireDepartment">
              {{ $t("equipmentAndLoss.nameOfFireDepartment") }}
              <span class="red--text"><strong> *</strong></span>
            </label>
            <v-text-field
              id="nameOfFireDepartment"
              v-model="localFormData.lossDetails[0].nameOfFireDepartment"
              :placeholder="$t('equipmentAndLoss.nameFirePlaceholder')"
              maxlength="50"
              :rules="
                localFormData.lossDetails[0].damageTypeCode === DamageType.FIRE
                  ? [rules.required]
                  : []
              "
              outlined
              dense
            ></v-text-field>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div
            v-show="
              localFormData.lossDetails[0].damageTypeCode ===
                DamageType.THEFT ||
              localFormData.lossDetails[0].damageTypeCode ===
                DamageType.ACCIDENT
            "
            class="theftOrAccident"
          >
            <label for="policeReportFile">
              {{ $t("equipmentAndLoss.policeReportFiled") }}
            </label>
            <v-radio-group
              v-model="localFormData.lossDetails[0].isPoliceReportFiled"
              :mandatory="false"
              row
            >
              <v-radio :value="true">
                <template #label>
                  <div>
                    {{ $t("equipmentAndLoss.yes") }}
                  </div>
                </template>
              </v-radio>
              <v-radio :value="false">
                <template #label>
                  <div>
                    {{ $t("equipmentAndLoss.no") }}
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
            <v-expand-transition>
              <div
                v-show="localFormData.lossDetails[0].isPoliceReportFiled"
                class="policeReportInfo"
              >
                <label for="nameOfPoliceDepartment">
                  {{ $t("equipmentAndLoss.nameOfPoliceDepartment") }}
                  <span class="red--text"><strong> *</strong></span>
                </label>
                <v-text-field
                  id="nameOfPoliceDepartment"
                  v-model="localFormData.lossDetails[0].nameOfPoliceDepartment"
                  maxlength="50"
                  :placeholder="$t('equipmentAndLoss.namePolicePlaceholder')"
                  :rules="
                    (localFormData.lossDetails[0].damageTypeCode ===
                      DamageType.THEFT ||
                      localFormData.lossDetails[0].damageTypeCode ===
                        DamageType.ACCIDENT) &&
                    localFormData.lossDetails[0].isPoliceReportFiled
                      ? [rules.required]
                      : []
                  "
                  outlined
                  dense
                ></v-text-field>
              </div>
            </v-expand-transition>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-show="displayFileUpload">
            <label for="reportOrIncidentNumber">
              {{ $t("equipmentAndLoss.reportOrIncidentNumber") }}
            </label>
            <v-text-field
              id="reportOrIncidentNumber"
              v-model="localFormData.lossDetails[0].reportOrIncidentNumber"
              maxlength="20"
              :placeholder="$t('equipmentAndLoss.reportIncidentPlaceholder')"
              outlined
              dense
            ></v-text-field>
            <div>
              <label for="uploadReport">{{
                $t("equipmentAndLoss.uploadReport")
              }}</label>
              <div class="mb-5">
                <v-btn
                  id="uploadReport"
                  elevation="3"
                  large
                  rounded
                  color="secondary"
                  @click="addFile"
                  >{{ $t("equipmentAndLoss.uploadButton") }}
                </v-btn>
                <v-file-input
                  id="add-file-input"
                  v-model="inputFile"
                  data-cy="add-document-add-files-input"
                  class="d-none"
                />
                <v-card
                  v-if="isInputFileSet === true"
                  max-height="500"
                  class="overflow-y-auto mt-3"
                  tile
                  outlined
                >
                  <v-list class="py-0" two-line>
                    <v-list-item>
                      <template v-if="localInputFile.uploading">
                        <v-progress-linear indeterminate />
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <div class="ml-8">
                            <v-list-item-title>
                              {{ localInputFile.info.fileName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ localInputFile.info.mimeType }} ({{
                                localInputFile.info.fileSize
                              }}
                              bytes)
                            </v-list-item-subtitle>
                          </div>
                        </v-list-item-content>
                        <v-list-item-icon class="my-auto">
                          <v-btn icon @click="removeFile">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-list-item-icon>
                      </template>
                    </v-list-item>
                    <template v-for="i in placeholderCount">
                      <v-skeleton-loader
                        :key="i"
                        type="list-item-avatar-two-line"
                      />
                    </template>
                  </v-list>
                </v-card>
              </div>
            </div>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div
            v-show="
              localFormData.lossDetails[0].damageTypeCode === DamageType.WATER
            "
            class="water"
          >
            <label for="sourceOfWaterDamage">
              {{ $t("equipmentAndLoss.sourceOfWaterDamage") }}
              <span class="red--text"><strong> *</strong></span>
            </label>
            <v-text-field
              id="sourceOfWaterDamage"
              v-model="localFormData.lossDetails[0].sourceOfWater"
              maxlength="50"
              :rules="
                localFormData.lossDetails[0].damageTypeCode === DamageType.WATER
                  ? [rules.required]
                  : []
              "
              :placeholder="$t('equipmentAndLoss.sourceWaterPlaceholder')"
              outlined
              dense
            ></v-text-field>
            <v-checkbox
              v-model="localFormData.lossDetails[0].isExposedToRain"
              :label="$t('equipmentAndLoss.equipmentExposedRain')"
            ></v-checkbox>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div
            v-show="localFormData.lossDetails[0].damageTypeCode"
            class="lossDetails"
          >
            <label for="causeOfLoss">
              {{ $t("equipmentAndLoss.causeOfLoss") }}
              <span class="red--text"><strong> *</strong></span>
            </label>
            <v-textarea
              id="causeOfLoss"
              v-model="causeOfLoss"
              :placeholder="$t('equipmentAndLoss.causeLossPlaceholder')"
              :rules="[rules.required, rules.max500Characters]"
              :maxlength="500"
              counter="500"
              rows="7"
              outlined
              dense
              no-resize
            ></v-textarea>
            <label for="locationOfLossDamage">
              {{ $t("equipmentAndLoss.locationOfLossDamage") }}
              <span class="red--text"><strong> *</strong></span>
            </label>
            <v-text-field
              id="locationOfLossDamage"
              v-model="localFormData.lossDetails[0].lossLocation"
              maxlength="50"
              :placeholder="
                $t('equipmentAndLoss.locationLossDamagePlaceholder')
              "
              :rules="[rules.required]"
              outlined
              dense
            ></v-text-field>
            <label for="lossRegion">
              {{ $t("equipmentAndLoss.lossRegion") }}
              <span class="red--text"><strong> *</strong></span>
            </label>
            <v-select
              id="lossRegion"
              v-model="localFormData.lossDetails[0].lossRegionCode"
              :items="lossRegionData"
              item-text="regionName"
              item-value="regionCode"
              :placeholder="$t('equipmentAndLoss.lossRegionPlaceholder')"
              :rules="[rules.required]"
              outlined
              dense
            ></v-select>
          </div>
        </v-expand-transition>
      </template>

      <!-- Other Insurance Coverage-->
      <template>
        <h2>
          {{ $t("equipmentAndLoss.otherInsuranceCoverage") }}
          {{ $t("equipmentAndLoss.ifAny") }}
        </h2>
        <label for="haveOtherInsurance">
          {{ $t("equipmentAndLoss.doYouHaveOtherInsurance") }}
        </label>
        <v-radio-group
          id="haveOtherInsurance"
          v-model="localFormData.additionalInsurance[0].hasAdditionalInsurance"
          :mandatory="false"
          row
        >
          <v-radio :value="true">
            <template #label>
              <div>{{ $t("equipmentAndLoss.yes") }}</div>
            </template>
          </v-radio>
          <v-radio :value="false">
            <template #label>
              <div>{{ $t("equipmentAndLoss.no") }}</div>
            </template>
          </v-radio>
        </v-radio-group>
        <v-expand-transition>
          <div
            v-show="
              localFormData.additionalInsurance[0].hasAdditionalInsurance ===
              true
            "
          >
            <label for="insurerName">
              {{ $t("equipmentAndLoss.insurerName") }}
              <span class="red--text"><strong> *</strong></span>
            </label>
            <v-text-field
              id="insurerName"
              v-model="localFormData.additionalInsurance[0].insurerName"
              maxlength="50"
              :placeholder="insurerNamePlaceholder"
              outlined
              dense
              :rules="
                localFormData.additionalInsurance[0].hasAdditionalInsurance ===
                true
                  ? [rules.required]
                  : []
              "
              type="text"
            ></v-text-field>
            <v-row justify-sm="space-between" class="contentRows pt-3">
              <v-col cols="12" sm="6">
                <label for="insurerPolicyNum">
                  {{ $t("equipmentAndLoss.policyNumber") }}
                </label>
                <v-text-field
                  id="insurerPolicyNum"
                  v-model="localFormData.additionalInsurance[0].policyNumber"
                  v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
                  maxlength="50"
                  :placeholder="policyNumberPlaceholder"
                  outlined
                  dense
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex">
                <div class="flex-grow-1">
                  <label
                    for="insurerPhoneNum"
                    v-text="$t('equipmentAndLoss.phoneNumber')"
                  />
                  <v-text-field
                    id="insurerPhoneNum"
                    v-model="
                      localFormData.additionalInsurance[0].contactPhoneNumber
                    "
                    v-mask="'(###) ###-####'"
                    :placeholder="phoneNumberPlaceholder"
                    :rules="
                      localFormData.additionalInsurance[0]
                        .hasAdditionalInsurance === true
                        ? [rules.phone]
                        : []
                    "
                    outlined
                    dense
                    type="tel"
                  />
                </div>
                <div
                  class="flex-grow-0 ml-2"
                  style="min-width: 80px; max-width: 80px"
                >
                  <label for="insurerPhoneExtension">&nbsp;</label>
                  <v-text-field
                    id="insurerPhoneExtension"
                    v-model="
                      localFormData.additionalInsurance[0]
                        .contactPhoneNumberExtension
                    "
                    v-mask="'######'"
                    type="number"
                    outlined
                    dense
                    :placeholder="$t('contactInfo.extn')"
                  >
                    <template #prepend><span>-</span></template>
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </template>

      <!-- Footer -->
      <stepControl
        :current_step="steps.current"
        :total_steps="steps.total"
        @onBack="onBack()"
        @onSave="validateForm('onSave')"
        @onSubmit="$emit('onSubmit')"
        @onContinue="validateForm('onContinue')"
      ></stepControl>
    </v-form>
  </v-container>
</template>

<script>
import stepControl from "../components/stepControl";
import Equipment from "@/data/Equipment";
import { UploadType } from "../data/UploadType";
import { DamageType } from "@/data/LossDetails";
import { getLossRegions } from "../service/LossRegionService";
import ValidationMixin from "@/mixins/ValidationMixin";

export default {
  name: "EquipmentAndLoss",
  components: {
    stepControl,
  },
  mixins: [ValidationMixin],
  props: {
    form_data: Object,
    steps: Object,
  },
  data: () => ({
    isCalendarOpen: false,
    isRowOpen: false,
    openRowNumber: null,
    addNewRow: false,
    editeditem: null,
    equipmentIndex: null,
    maxRowNumber: -1,
    rules: {
      required: (value) => !!value || "Required field!",
      max500Characters: (value) =>
        /^(.{0,500})?$/s.test(value) || "Must be 500 characters or less",
      notFutureDate: (value) => {
        const today = new Date();
        const date = new Date(value);
        if (!isFinite(date) || today >= date) {
          return true;
        } else {
          return "Cannot be a future date";
        }
      },
      phone: (value) => {
        const pattern = /(\((\d{3})\)\s(\d{3})-(\d{4}))|^$/;
        return pattern.test(value) || "Invalid phone number.";
      },
    },
    validFileTypes: [
      "png",
      "jpeg",
      "gif",
      "tif",
      "mov",
      "pdf",
      "mp4",
      "jpg",
      "bmp",
    ],
    placeholderCount: 0,
    localInputFile: null,
    lossRegionDataSet: false,
    lossRegionData: [{ id: 0, regionName: "None", regionOrder: 0 }],
    hasInvalidFileError: false,
    DamageType,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("equipmentAndLoss.equipmentList.equipmentType"),
          value: "type",
        },
        {
          text: this.$t("equipmentAndLoss.equipmentList.make"),
          value: "make",
        },
        {
          text: this.$t("equipmentAndLoss.equipmentList.model"),
          value: "model",
        },
        {
          text: this.$t("equipmentAndLoss.equipmentList.serial"),
          value: "serialNumber",
        },
        {
          text: this.$t("equipmentAndLoss.equipmentList.year"),
          value: "year",
        },
      ];
    },
    localFormData() {
      return this.form_data;
    },
    inputFile: {
      get: function () {
        return this.localInputFile;
      },
      set: function (addedFile) {
        if (this.isValidFile(addedFile)) {
          this.addFileInfo(addedFile, {
            fileName: addedFile.name,
            mimeType: addedFile.type,
            fileSize: addedFile.size,
          });
        } else {
          this.hasInvalidFileError = true;
        }
      },
    },
    isInputFileSet: function () {
      return this.localInputFile ? true : false;
    },
    insurerNamePlaceholder() {
      return this.$t("equipmentAndLoss.insurerName");
    },
    policyNumberPlaceholder() {
      return this.$t("equipmentAndLoss.policyNumber");
    },
    phoneNumberPlaceholder() {
      return this.$t("equipmentAndLoss.phoneNumber");
    },
    controlActionsLocation: function () {
      let result = 0;
      if (this.isMobile) {
        result += -48;
        result +=
          (this.form_data.equipment.length - this.equipmentIndex - 1) * -240;
      } else {
        result +=
          (this.localFormData.equipment.length - this.equipmentIndex) * -48;
      }
      return result;
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.xsOnly;
    },
    todayDate: function () {
      return new Date().toISOString().slice(0, 10);
    },
    occurDateFormatted: {
      get: function () {
        return this.localFormData.lossDetails[0].lossDate;
      },
      set: function (value) {
        this.localFormData.lossDetails[0].lossDate = value;
      },
    },
    occurDateFormattedPicker: {
      get: function () {
        return this.isDateValid(new Date(this.occurDateFormatted))
          ? this.formatDate(this.occurDateFormatted)
          : this.todayDate;
      },
      set: function (value) {
        this.occurDateFormatted = value;
      },
    },
    // lossRegions: function() {
    //   if(!this.lossRegionDataSet) {
    //     getLossRegions().then((lossRegions) => { this.lossRegionData = lossRegions });
    //     this.lossRegionDataSet = true;
    //   }
    //
    //   return this.lossRegionData;
    // },
    // inputFile: function () {
    //   return this.form_data.inputFile;
    // },
    // regions: function () {
    //   return regions;
    // },
    localInsurerPhone: {
      get: function () {
        return this.localFormData.insurerPhoneNumber;
      },
      set: function (newVal) {
        const phoneNum = this.removeNonPhoneNumbers(newVal);
        this.localFormData.insurerPhoneNumber = phoneNum;
        return newVal;
      },
    },
    displayFileUpload: function () {
      return (
        this.localFormData.lossDetails[0].damageTypeCode === "FIRE" ||
        ((this.localFormData.lossDetails[0].damageTypeCode === "THEFT" ||
          this.localFormData.lossDetails[0].damageTypeCode === "ACCIDENT") &&
          this.localFormData.lossDetails[0].isPoliceReportFiled)
      );
    },
    reportOrIncidentNumber: {
      get: function () {
        return this.localFormData.lossDetails.reportOrIncidentNumber;
      },
      set: function (value) {
        this.localFormData.lossDetails.reportOrIncidentNumber = value;
      },
    },
    causeOfLoss: {
      get: function () {
        return this.localFormData.lossDetails[0].causeOfLoss;
      },
      set: function (value) {
        this.localFormData.lossDetails[0].causeOfLoss = value.substring(0, 500);
      },
    },
  },
  watch: {
    occurrenceDate(newVal) {
      this.occurDateFormatted = this.formatDate(newVal);
    },
  },
  mounted() {
    if (this.localFormData) {
      if (
        this.localFormData.equipment &&
        this.localFormData.equipment.length > 0
      ) {
        this.maxRowNumber = this.localFormData.equipment.length - 1;
      }

      if (this.localFormData.fileUploads) {
        const reportFile = this.localFormData.fileUploads.find(
          (file) => file.uploadType === UploadType.Report
        );

        if (reportFile) {
          this.localInputFile = reportFile;
        }
      }
    }
    if (!this.lossRegionDataSet) {
      getLossRegions()
        .then((lossRegions) => {
          this.lossRegionData = lossRegions;
        })
        .catch((error) => {
          this.$emit(
            "form-error",
            "Error retrieving loss regions . Please try again later or contact the administration team at (800) 833-3549."
          );
        });
      this.lossRegionDataSet = true;
    }
  },
  methods: {
    formatDate: function (date) {
      const dateConverted = new Date(date);

      if (!date || !this.isDateValid(dateConverted)) return null;

      return dateConverted.toISOString().slice(0, 10);
    },
    onBack: function () {
      this.$emit("onBack", this.localFormData);
    },
    removeNonPhoneNumbers: function (val) {
      return val.slice(1, 4) + val.slice(6, 9) + val.slice(10, 14);
    },
    validateForm: function (emitVal) {
      const containsEquipment = this.localFormData.equipment.length > 0;
      const validated = this.$refs.form.validate();
      if (validated && containsEquipment) {
        this.$emit(emitVal);
      } else {
        let targetClass = "";
        if (!containsEquipment) {
          this.$emit("form-error", "Please add at least one equipment detail");
          targetClass = ".v-data-table";
        } else {
          this.$emit("form-error", "Please complete required fields");
          targetClass = ".v-messages.error--text:first-of-type";
        }
        this.$nextTick(() => {
          const el = this.$el.querySelector(targetClass);
          this.$vuetify.goTo(el);
          return;
        });
      }
    },
    openRow: function (row) {
      if (!this.isRowOpen) {
        this.isRowOpen = true;
        this.openRowNumber = row.row_number;
        this.editeditem = new Equipment();
        Object.assign(this.editeditem, row);

        this.localFormData.equipment.find((equipment, index) => {
          if (equipment.row_number === this.openRowNumber) {
            this.equipmentIndex = index;
            return true;
          }
        });
      }
    },
    cancelChanges: function () {
      this.resetData();
    },
    saveChanges: function () {
      if (this.equipmentIndex >= 0 && this.editeditem) {
        this.localFormData.equipment.splice(
          this.equipmentIndex,
          1,
          this.editeditem
        );
      }

      this.resetData();
    },
    addRow: function () {
      if (!this.isRowOpen) {
        this.addNewRow = true;
        this.isRowOpen = true;
        this.maxRowNumber++;
        this.equipmentIndex = this.localFormData.equipment.length;
        const tempRowNum = this.maxRowNumber;

        this.editeditem = new Equipment();
        this.editeditem.row_number = tempRowNum;
        this.editeditem.type = "";
        this.editeditem.make = "";
        this.editeditem.model = "";
        this.editeditem.serialNumber = "";
        this.editeditem.year = "";
        this.openRowNumber = tempRowNum;

        //Object.assign(this.editeditem, newEquipment);
        this.localFormData.equipment.push(this.editeditem);
      }
    },
    deleteRow: function () {
      this.localFormData.equipment.splice(this.equipmentIndex, 1);
      this.resetData();
    },
    resetData: function () {
      this.isRowOpen = false;
      this.openRowNumber = null;
      this.equipmentIndex = null;
      this.addNewRow = false;
      this.editeditem = null;

      this.renumberEquipmentRows();
    },
    row_classes(item) {
      if (this.isRowOpen && item.row_number === this.openRowNumber) {
        return "selectedRow";
      }
    },
    addFile: function () {
      document.getElementById("add-file-input").click();
    },
    removeFile: function () {
      if (this.localInputFile) {
        this.localFormData.removeFileUpload(this.localInputFile);
        this.localInputFile = null;
      }
    },
    addFileInfo: function (file, info) {
      //this.localFormData.fire.inputFile = this.formatFilesInfo(file, info);

      this.removeFile();
      this.localInputFile = this.formatFilesInfo(file, info);
      this.localFormData.fileUploads.push(this.localInputFile);
    },
    formatFilesInfo: function (file, info) {
      const result = {
        file: file,
        info: info,
        uploading: false,
        uploadType: UploadType.Report,
      };
      return result;
    },
    renumberEquipmentRows: function () {
      let rowNumber = 0;

      this.localFormData.equipment.forEach((equipment) => {
        equipment.row_number = rowNumber++;
      });

      this.maxRowNumber = this.localFormData.equipment.length - 1;
    },
    filterDateInput: function (evt) {
      evt = evt ? evt : window.event;
      const key = evt.key.toString();
      const value = evt.target.value.toString();
      const dateInputTest = /^[-/0-9]*$/;
      const digitTest = /^[0-9]*$/;

      // If the enter key is pressed, toggle between displaying the date picker or not.
      if (key.toLowerCase() === "enter") {
        this.isCalendarOpen = !this.isCalendarOpen;
      }

      // Restrict input to only digits or delimiters.
      if (!dateInputTest.test(key)) {
        evt.preventDefault();
      }

      // If one delimiter has been chosen while typing a value, do not allow the opposite to be used the next time.
      else if (
        (key == "-" && value.indexOf("/") > 0) ||
        (key == "/" && value.indexOf("-") > 0)
      ) {
        evt.preventDefault();
      }

      // Ensure that a delimiter is not the first character in the value, the character before the current one is
      // a digit, and there are not already two delimiters.
      else if (
        (key == "-" || key == "/") &&
        (value.length == 0 ||
          !digitTest.test(value[value.length - 1]) ||
          this.getCharacterCount(value, key) > 1)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getCharacterCount: function (value, character) {
      let count = 0;

      for (const valueCharacter of value.toString()) {
        if (valueCharacter == character) {
          count++;
        }
      }

      return count;
    },
    isDateValid: function (d) {
      return d instanceof Date && !isNaN(d);
    },
    lossDateLeft: function (evt) {
      const value = evt.target.value.toString();

      if (this.isDateValid(new Date(value))) {
        this.occurDateFormatted = this.formatDate(value);
      } else {
        this.occurDateFormatted = "";
      }
    },
    filterEquipmentYear(e) {
      let keyCode;

      if (window.event) {
        keyCode = e.keyCode;
      } else if (e.which) {
        keyCode = e.which;
      }

      if (keyCode) {
        if (!/\d/.test(String.fromCharCode(keyCode))) {
          e.preventDefault();
        }
      }
    },
  },
};
</script>

<style>
tr td {
  cursor: pointer;
}
.v-data-table__empty-wrapper {
  display: none;
}
.selectedRow {
  background-color: rgb(233, 236, 239) !important;
}
.backgroundRows {
  background-color: rgb(246, 246, 246) !important;
  color: rgba(162, 162, 162, 0.25) !important;
}

.backgroundRows:hover {
  background-color: transparent !important;
}

.controlActions {
  z-index: 3;
  position: relative;
  width: fit-content;
  margin: auto;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  background-color: rgb(233, 236, 239);
}
.icons {
  color: rgb(0, 55, 100) !important;
}
.icons:hover {
  color: rgb(0, 103, 188) !important;
}
.v-input--selection-controls {
  padding-top: 0;
  margin-top: 0;
}

/* Below needed to remove number input buttons */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>

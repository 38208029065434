export default class LossDetails {
  public damageTypeCode: string | undefined;
  public lossDate: string | undefined;
  public lossRegionCode: string | undefined;
  public lossLocation: string | undefined;
  public causeOfLoss: string | undefined;
  public isPoliceReportFiled = false;
  public nameOfPoliceDepartment: string | undefined;
  public reportOrIncidentNumber: string | undefined;
  public nameOfFireDepartment: string | undefined;
  public sourceOfWater: string | undefined;
  public isExposedToRain: boolean | undefined;
}

export enum DamageType {
  FIRE = "FIRE",
  THEFT = "THEFT",
  WATER = "WATER",
  ACCIDENT = "ACCIDENT",
  OTHER = "OTHER",
}

import axios from "axios";
//import { handleError } from "@/utilities/MessageHandler";

const sesDigitalClaimsAxios = axios.create({ baseURL: "/api" });

const debug = false;

// Request Interceptor
sesDigitalClaimsAxios.interceptors.request.use(
  function (config) {
    if (debug) {
      console.debug(`Request made to ${config.url}:`, config);
    }
    return config;
  },
  function (error) {
    console.error(error);
    return Promise.reject(error);
  }
);

// Response Interceptor
sesDigitalClaimsAxios.interceptors.response.use(
  function (response) {
    if (debug) {
      console.debug(`Response from ${response.config.url}:`, response);
    }
    return response;
  },
  function (error) {
    const ignoreInterceptors = !!error.config.headers.ignoreInterceptors;
    if (!ignoreInterceptors) {
      console.error(`Received Error from ${error.config.url}`);
      console.error("Config:", error.config);
      console.error("Request:", error.request);
      console.error("Response:", error.response);
      console.error(error);
    }
    return Promise.reject(error);
  }
);

export default sesDigitalClaimsAxios;

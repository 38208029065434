<template>
  <v-container fluid>
    <v-row v-if="form_data" class="mt-6">
      <v-col id="stepDescription" cols="12" align="center">
        <v-img contain max-width="200px" src="../assets/FNOL.png"></v-img>
        <h1 class="text-h4 font-weight-light">
          {{ $t("success.successMessage") }}
        </h1>
        <v-row class="py-2" no-gutters justify="center" align="center">
          <v-col cols="12" sm="auto">
            <h3>
              {{ $t("success.claimRefNumber") }}
            </h3>
          </v-col>
          <v-col cols="12" sm="auto">
            <div class="pl-2" style="color: #33b765; font-size: 2rem">
              {{ form_data.claimReferenceNumber }}
            </div>
          </v-col>
        </v-row>
        <hr />
        <div class="my-4">
          {{ $t("success.claimExpectancy") }}
        </div>
        <div>
          <strong>
            {{ $t("success.contactNumber") }}
          </strong>
        </div>
        <div>800-833-3549</div>
      </v-col>
    </v-row>
    <v-row v-else class="mt-6">
      <v-col id="stepDescription" cols="12" align="center">
        <h1 class="text-h4 font-weight-light">
          {{ $t("success.submittingMessage") }}
        </h1>
        <v-row class="py-2" no-gutters justify="center" align="center">
          <v-col cols="12" sm="auto">
            <v-progress-circular
              :size="100"
              :width="10"
              color="red"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
        <hr />
        <div class="my-4">
          {{ $t("success.claimExpectancy") }}
        </div>
        <div>
          <strong>
            {{ $t("success.contactNumber") }}
          </strong>
        </div>
        <div>800-833-3549</div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "SuccessPage",
  props: {
    form_data: Object,
  },
};
</script>

<template>
  <v-container fluid>
    <!-- error handler -->
    <v-row v-if="hasFormError" justify="center">
      <v-col
        cols="12"
        md="6"
        sm="8"
        style="z-index: 1; position: fixed; top: 10px"
      >
        <v-alert
          v-model="hasFormError"
          transition="fade-transition"
          icon="mdi-cancel"
          color="error"
          dismissible
          elevation="3"
          type="error"
        >
          {{ formErrorMessage }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="!hasSubmitted">
      <v-col
        id="stepDescription"
        cols="12"
        lg="4"
        md="4"
        sm="12"
        align="center"
        class="section-divider"
      >
        <v-btn
          v-for="n in step_controls.length"
          :key="n"
          class="ma-2"
          style="border-width: 3px"
          outlined
          fab
          color="indigo"
          :disabled="current_step !== n"
        >
          <v-icon v-show="current_step > n">mdi-check-bold</v-icon>
          {{ n }}
        </v-btn>
        <step-icon
          :title="stepTitle"
          :description="stepDescription"
          :img-name="stepIcon"
          :step-number="stepNumber"
        >
          <template v-if="current_step === 3" #details>
            <tr>
              <td style="text-align: center">
                <span>
                  <ul style="margin: 5px; text-align: left">
                    <li>{{ $t("fileUpload.leaseAgreement") }}</li>
                    <li>
                      {{ $t("fileUpload.originalPurchaseInvoice") }}
                    </li>
                    <li>
                      {{ $t("fileUpload.prePostDamagePhotos") }}
                    </li>
                  </ul>
                </span>
              </td>
            </tr>
            {{ $t("fileUpload.lenderCopyLeaseAgreement") }}
          </template>
        </step-icon>
      </v-col>
      <v-col id="stepForm" cols="12" lg="8" md="8">
        <v-row justify="center">
          <v-col lg="9" md="11">
            <component
              :is="step_controls[current_step - 1].form"
              :steps="{ current: current_step, total: step_controls.length }"
              :form_data="claim"
              @form-error="formError"
              @onContinue="onContinue"
              @onBack="onBack"
              @onSave="onSave"
              @onSubmit="onSubmit"
            ></component>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <success v-else :form_data="savedClaim"></success>
  </v-container>
</template>

<script>
import Claim from "@/data/Claim";
import ContactInfo from "./ContactInfo";
import EquipmentAndLoss from "./EquipmentAndLoss.vue";
import FileUpload from "./FileUpload.vue";
import stepControl from "@/components/stepControl.vue";
import success from "@/components/successPage.vue";
import { addClaim } from "@/service/ClaimService";
import StepIcon from "@/steps/StepIcon";

export default {
  components: {
    StepIcon,
    ContactInfo,
    EquipmentAndLoss,
    FileUpload,
    stepControl,
    success,
  },
  props: {
    //   current_step: {
    //     type: Number,
    //     default: 1
    // },
  },
  data: () => ({
    step_controls: [
      { form: "ContactInfo", description: "ContactInfoDesc" },
      { form: "EquipmentAndLoss", description: "EquipmentAndLossDesc" },
      { form: "FileUpload", description: "FileUploadDesc" },
    ],
    claim: new Claim(),
    savedClaim: null,
    testVal: false,
    hasFormError: false,
    formErrorMessage: null,
    current_step: 1,
    hasSubmitted: false,
    snackbar: true,
  }),
  computed: {
    stepTitle() {
      switch (this.current_step) {
        case 1:
          return this.$t("contactInfo.desc.title");
        case 2:
          return this.$t("equipmentAndLoss.desc.title");
        case 3:
          return this.$t("fileUpload.desc.title");
      }
      return null;
    },
    stepIcon() {
      switch (this.current_step) {
        case 1:
          return "step1.png";
        case 2:
          return "step2.png";
        case 3:
          return "upload.png";
      }
      return null;
    },
    stepDescription() {
      switch (this.current_step) {
        case 1:
          return this.$t("contactInfo.desc.description");
        case 2:
          return this.$t("equipmentAndLoss.desc.description");
        case 3:
          return this.$t("fileUpload.desc.description");
      }
      return null;
    },
    stepNumber() {
      switch (this.current_step) {
        case 1:
          return this.$t("contactInfo.desc.stepNumber");
        case 2:
          return this.$t("equipmentAndLoss.desc.stepNumber");
        case 3:
          return this.$t("fileUpload.desc.stepNumber");
      }
      return null;
    },
  },
  methods: {
    onSubmit: function () {
      if (!this.savedClaim) {
        this.hasSubmitted = true;
        const claimProperties = Object.assign({}, this.claim);
        claimProperties.locale = this.$vuetify.lang.current;
        delete claimProperties.fileUploads;

        let reports = null;
        let files = null;

        if (this.claim.fileUploads) {
          reports = this.claim.fileUploads
            .filter((f) => f.uploadType === 1)
            .map((f) => f.file);
          files = this.claim.fileUploads
            .filter((f) => f.uploadType === 2)
            .map((f) => f.file);
        } else {
          files = {};
          reports = {};
        }

        grecaptcha.ready(() => {
          grecaptcha
            .execute("6LeN4ksaAAAAAJtdGWbzkUkBeZY-sgloCS3nj6ph", {
              action: "homepage",
            })
            .then((token) => {
              if (token) {
                addClaim(claimProperties, files, reports, token)
                  .then((newClaim) => {
                    this.savedClaim = newClaim;
                  })
                  .catch((error) => {
                    this.formError(
                      "An error has occurred. Please try again later or contact the administration team at (800) 833-3549."
                    );
                  });
              } else {
                this.formError("Recaptcha Validation Failed");
              }
            });
        });
      }

      // this.$router.push({ path: '/success' });
      //add submit button click logic here
    },
    onContinue: function (claim) {
      // let step = parseInt(this.current_step);
      // step += 1;
      this.current_step += 1;
      this.hasFormError = false;
      this.$vuetify.goTo(0);
      if (claim) {
        this.claim = claim;
      }
      //this.$router.push({ path: `/steps/${step}` })
    },
    onBack: function (claim) {
      // let step = parseInt(this.current_step);
      // step -= 1;
      this.current_step -= 1;
      this.$vuetify.goTo(0);
      if (claim) {
        this.claim = claim;
      }

      //this.$router.push({ path: `/steps/${step}` })
    },
    onSave: function () {
      //add save for later button click logic here
      console.log("save the claim");
    },
    formError: function (errorMessage) {
      this.hasFormError = true;
      this.formErrorMessage = errorMessage;
    },
  },
};
</script>
<style scoped>
@media (max-width: 960px) {
  .section-divider {
    border-bottom: solid #f7ecfa 2px;
  }
}

@media (min-width: 961px) {
  .section-divider {
    border-right: solid #f7ecfa 2px;
  }
}
</style>

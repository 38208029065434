import Vue from "vue";

import validations from "@/util/Validations";

const f = (val: unknown): string | boolean => "";
type ValidationFunction = typeof f;
const g = (params?: unknown | undefined): ValidationFunction => f;
type RuleFunction = typeof g;

export default Vue.extend({
  computed: {
    /**
     * Returns a collection of Vuetify Form Validation Functions
     */
    validationRules(): Record<string, RuleFunction> {
      return {
        required:
          () =>
          (val: unknown): boolean | string => {
            return (
              validations.required(val) ||
              (this.$t("validations.required") as string)
            );
          },
        maxNCharacters:
          (count: unknown) =>
          (val: unknown): boolean | string => {
            const str = val as string | null;
            const c = count as number;
            return (
              validations.maxNCharacters(str, c) ||
              (this.$tc("validations.maxCharacters", c) as string)
            );
          },
        email:
          () =>
          (val: unknown): boolean | string => {
            return (
              validations.email(val as string | null) ||
              (this.$t("validations.email") as string)
            );
          },
        phone:
          () =>
          (val: unknown): boolean | string => {
            return (
              validations.phone(val as string | null) ||
              (this.$t("validations.phone") as string)
            );
          },
      };
    },
  },
  methods: {
    isValidFile(file: File): boolean {
      const type = (file.type || "").toLowerCase();
      return (
        type.startsWith("image/") ||
        type.startsWith("video/") ||
        type === "application/pdf"
      );
    },
  },
});

<template>
  <v-container
    ><!-- error handler -->
    <v-row v-if="hasInvalidFileError" justify="center">
      <v-col
        cols="12"
        md="6"
        sm="8"
        style="z-index: 1; position: fixed; top: 10px"
      >
        <v-alert
          v-model="hasInvalidFileError"
          transition="fade-transition"
          icon="mdi-cancel"
          color="error"
          dismissible
          elevation="3"
          type="error"
        >
          {{ $t("fileUpload.validFileFormats") }}
        </v-alert>
      </v-col>
    </v-row>

    <v-form ref="form">
      <h1>
        {{ $t("fileUpload.photosVideosDocuments") }}
      </h1>
      <v-card
        id="drag-and-drop"
        max-height="500"
        height="400"
        class="overflow-y-auto px-2 d-flex justify-center align-center align-content-center flex-column"
        tile
        outlined
        @drop.prevent="onDrop($event)"
        @dragover.prevent="$event.dataTransfer.dropEffect = 'copy'"
      >
        <div class="d-flex align-center text-center">
          <v-img
            contain
            height="130"
            width="130"
            src="../assets/fileUploadBox.png"
          ></v-img>
        </div>
        <v-card-title class="pb-0">{{
          $t("fileUpload.dragAndDrop")
        }}</v-card-title>
        <v-btn
          id="uploadReport"
          elevation="3"
          large
          rounded
          color="secondary"
          @click="addFile"
          >{{ $t("fileUpload.uploadButton") }}</v-btn
        >
      </v-card>
      <div class="py-3">
        {{ $t("fileUpload.validFileFormats") }}
      </div>
      <v-file-input
        id="add-file-input"
        v-model="fileUploads"
        hide-input
        multiple
        class="d-none"
      />
      <div v-if="fileUploadsFiltered.length === 0">No items to show...</div>
      <v-card v-else class="overflow-y-auto" tile outlined>
        <v-list class="py-0" two-line>
          <template v-for="(file, i) in fileUploads">
            <v-list-item
              v-if="file.uploadType === UploadType.FileUpload"
              :key="i"
            >
              <template v-if="file.uploading">
                <v-progress-linear indeterminate />
              </template>
              <template v-else>
                <v-list-item-content>
                  <div class="ml-8">
                    <v-list-item-title>
                      {{ file.info.fileName }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ file.info.mimeType }} ({{ file.info.fileSize }} bytes)
                    </v-list-item-subtitle>
                  </div>
                </v-list-item-content>
                <v-list-item-icon class="my-auto">
                  <v-btn icon @click="removeFile(file)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </template>
            </v-list-item>
          </template>
          <template v-for="i in placeholderCount">
            <v-skeleton-loader :key="i" type="list-item-avatar-two-line" />
          </template>
        </v-list>
      </v-card>
      <stepControl
        :current_step="steps.current"
        :total_steps="steps.total"
        @onBack="onBack()"
        @onSubmit="$emit('onSubmit')"
        @onContinue="$emit('onContinue')"
      ></stepControl>
    </v-form>
  </v-container>
</template>

<script>
import stepControl from "../components/stepControl";
import { UploadType } from "../data/UploadType";
import ValidationMixin from "@/mixins/ValidationMixin";

export default {
  name: "FileUpload",
  components: {
    stepControl,
  },
  mixins: [ValidationMixin],
  props: {
    form_data: Object,
    steps: Object,
  },
  data: () => ({
    inputFiles: [],
    validFileTypes: [
      "png",
      "jpeg",
      "gif",
      "tif",
      "mov",
      "pdf",
      "mp4",
      "jpg",
      "bmp",
    ],
    placeholderCount: 0,
    hasInvalidFileError: false,
    UploadType,
  }),
  computed: {
    dragToUpload() {
      return this.$t("fileUpload.dragToUpload");
    },
    fileUploads: {
      get: function () {
        return this.localFormData.fileUploads;
      },
      set: function (addedFiles) {
        addedFiles.forEach((addedFile) => {
          if (this.isValidFile(addedFile)) {
            this.addFileInfo(addedFile, {
              fileName: addedFile.name,
              mimeType: addedFile.type,
              fileSize: addedFile.size,
            });
          } else {
            this.hasInvalidFileError = true;
          }
        });
      },
    },
    fileUploadsFiltered: function () {
      return this.fileUploads.filter(
        (file) => file.uploadType === UploadType.FileUpload
      );
    },
    localFormData() {
      return this.form_data;
    },
  },
  methods: {
    onDrop: function (e) {
      this.fileUploads = Array.from(e.dataTransfer.files);
    },
    addFile: function () {
      document.getElementById("add-file-input").click();
    },
    onBack: function () {
      this.$emit("onBack", this.localFormData);
    },
    removeFile: function (fileToRemove, forceRemove) {
      // determine whether to remove from the files parent or the root
      const files = fileToRemove.parent
        ? fileToRemove.parent.info.attachments
        : this.fileUploads;
      const i = files.findIndex((file) => {
        return file === fileToRemove;
      });

      // remove the file if we are either forcing it from a remove action click
      // or from removing the last child after uploading and disabling the parent
      if (
        forceRemove ||
        !fileToRemove.info.attachments ||
        !fileToRemove.info.attachments.length
      ) {
        files.splice(i, 1);

        // remove the parent if it has been uploaded and disabled and has no children left
        if (
          fileToRemove.parent &&
          fileToRemove.parent.disabled &&
          !files.length
        ) {
          this.removeFile(fileToRemove.parent, true);
        }
      } else {
        // disable the parent since we are not forcing the removal and it still has children
        fileToRemove.disabled = true;
        fileToRemove.uploading = false;
      }
    },
    addFileInfo: function (file, info) {
      const formattedFilesInfo = this.formatFilesInfo(file, info);
      this.fileUploads.push(formattedFilesInfo);
    },
    formatFilesInfo: function (file, info) {
      const result = {
        file: file,
        info: info,
        uploading: false,
        uploadType: UploadType.FileUpload,
      };
      return result;
    },
  },
};
</script>

<style>
#drag-and-drop {
  border: 3px dashed darkgrey !important;
  border-radius: 8px !important;
}
</style>

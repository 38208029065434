import LossDetails from "@/data/LossDetails";
import Equipment from "./Equipment";
import InputFile from "@/data/InputFile";
import AdditionalInsurance from "@/data/AdditionalInsurance";

export default class Claim {
  claimantTypeCode: ClaimantType = ClaimantType.LESSOR;
  companyName: string | undefined;
  companyAddress: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
  phoneExtension: string | undefined;
  emailAddress: string | undefined;
  customerName: string | undefined;
  leasingCompanyName: string | undefined;
  contractNumber: string | undefined;
  vendorLesseeAddress: string | undefined;
  vendorLesseePhoneNumber: string | undefined;
  vendorLesseePhoneExtension: string | undefined;
  vendorLesseeEmail: string | undefined;
  policyNumber: string | undefined;
  locale: string | undefined;

  equipment: Array<Equipment> = [];

  lossDetails: Array<LossDetails> = [new LossDetails()];

  additionalInsurance: Array<AdditionalInsurance> = [new AdditionalInsurance()];

  fileUploads: Array<InputFile> = [];

  removeFileUpload(file: InputFile) {
    let indexToRemove = -1;

    for (let i = 0; i < this.fileUploads.length; i++) {
      if (this.fileUploads[i] === file) {
        indexToRemove = i;
      }
    }

    if (indexToRemove >= 0) {
      this.fileUploads.splice(indexToRemove, 1);
    }
  }
}

export enum ClaimantType {
  LESSEE = "LESSEE",
  EQUIPMENT_VENDOR = "EQUIPMENT_VENDOR",
  LESSOR = "LESSOR",
}


import Vue from "vue";

export default Vue.extend({
  props: {
    stepNumber: {
      type: String,
      required: true,
    },
    imgName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
});

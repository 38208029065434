export default {
  continueButton: "Continuer",
  requiredLabel: "Obligatoire",
  saveForLaterButton: "Sauvegarder pour plus tard",
  backButton: "Retour",
  submitButton: "Soumettre",
  language: {
    en: {
      name: "English",
      countryCode: "usa",
    },
    fr: {
      name: "Français",
      countryCode: "fra",
    },
  },
  contactInfo: {
    instructions:
      "Veuillez fournir le plus de renseignements possibles.* Indique que le champ est obligatoire",
    title: "Information relative à une nouvelle réclamation",
    customerTypeSelectTitle: "Êtes-vous un / une",
    lesseeCustomer: "Preneur à bail / client",
    equipmentDealerVendor: "Concessionnaire / vendeur d'équipement",
    insuredLessor: "Bailleur / Assuré",
    yourInformationTitle: "Vos informations",
    companyName: "Nom de la compagnie",
    companyAddress: "Adresse postale de la compagnie",
    yourContactInformationTitle: "Vos coordonnées",
    firstName: "Prénom",
    lastName: "Nom de famille",
    phoneNumber: "No de téléphone",
    extn: "No de poste",
    emailAddress: "Adresse courriel",
    leasingPolicyInformationTitle:
      "Renseignements sur la police / crédit-bail (Obligatoire)",
    lesseeCustomerName: "Nom du preneur à bail / client",
    leasingCompanyName:
      "Nom de l'institution financière ou de la société de crédit-bail",
    contractNumber: "Numéro du contrat ou du bail",
    policyNumber: "Numéro de la police d'assurance",
    lesseeAddress: "Adresse postale du preneur à bail / client",
    lesseePhone: "No de téléphone du preneur à bail / client",
    lesseeEmail: "Adresse courriel du preneur à bail / client",
    desc: {
      stepNumber: "ÉTAPE 1",
      title: "Coordonnées",
      description: "Veuillez fournir vos coordonnées",
    },
  },
  equipmentAndLoss: {
    title: "Équipement endommagé",
    instructions: "Veuillez fournir le plus de renseignements possibles",
    information:
      "Veuillez cliquer sur le lien ''Ajout d'équipement'' ci-dessous pour ajouter des détails sur tout équipement endommagé. Cliquez sur l'icône Coche pour sauvegarder chacune des pièces de l'équipement endommagé que vous avez enregistrées.",
    addEquipment: "Ajouter de l'équipement",
    lossDetails: "Détails concernant le sinistre",
    lossOccurrenceDate: "Date de l'événement du sinistre",
    typeOfDamage: "Type de dommages",
    fire: "Feu",
    theftOfRobbery: "Vol ou vol qualifié",
    water: "Dégâts d'eau",
    accident: "Accident",
    other: "Autres",

    nameOfFireDepartment: "Nom du service d'incendie",
    nameFirePlaceholder:
      "i.e., Service d'incendie de la localité de Hastings-on-Hudson NY",
    reportOrIncidentNumber: "Numéro du rapport ou de l'incident",
    reportIncidentPlaceholder: "No du rapport / incident",

    uploadReport: "Téléverser le rapport",
    uploadButton: "EXPLORER POUR CHERCHER UN FICHIER",

    policeReportFiled: "Est-ce qu'un rapport de police a été déposé",
    nameOfPoliceDepartment: "Nom du service de police",
    namePolicePlaceholder: "i.e., NYPD",

    sourceOfWaterDamage: "Source des dégâts d'eau",
    sourceWaterPlaceholder: "i.e., Extincteurs automatiques",
    equipmentExposedRain: "Est-ce que l'équipement a été exposé à la pluie ?",

    causeOfLoss: "Cause du sinistre",
    causeLossPlaceholder: "Décrivez ce qui s'est passé (500 caractères max) ",
    locationOfLossDamage: "Emplacement du sinistre / accident",
    locationLossDamagePlaceholder:
      "Indiquez l'emplacement physique (i.e., l'adresse) où s'est produit le sinistre / accident",
    lossRegion: "Région de perte",
    lossRegionPlaceholder: "Select US State, Territory, or Canadian Province",

    otherInsuranceCoverage: "Autre couverture",
    ifAny: "(Si oui)",
    doYouHaveOtherInsurance: "Avez-vous d'autres assurances ?",
    yes: "Oui",
    no: "Non",
    insurerName: "Nom de la compagnie d'assurances",
    policyNumber: "Numéro de la police d'assurance",
    phoneNumber: "No de téléphone",
    equipmentList: {
      equipmentType: "Type d'équipement",
      make: "Marque",
      model: "Modèle",
      serial: "No de série",
      year: "No de série",
    },
    desc: {
      stepNumber: "ÉTAPE 2",
      title: "Informations Concernant",
      description: "L'équipement Et Le Sinistre",
    },
  },
  fileUpload: {
    leaseAgreement: "Convention de bail",
    originalPurchaseInvoice: "Facture d'achat originale",
    prePostDamagePhotos: "Photos avant / après dommages",
    dragAndDrop: "Glisser pour téléverser ici ou",
    uploadButton: "EXPLORER POUR CHERCHER UN FICHIER",
    lenderCopyLeaseAgreement:
      "* Votre bailleur recevra une copie de votre convention de bail et de votre facture",
    photosVideosDocuments: "Photos, vidéos ou documents",
    validFileFormats:
      "Formats de fichier valides : PNG, JPEG, GIF, TIFF, MMV, MOV, MP4, DOC, DOCX, PDF, JPG",
    dragToUpload: "Glisser pour téléverser ici",
    desc: {
      stepNumber: "ÉTAPE 3",
      title: "Téléversement de fichier",
      description:
        "Veuillez téléverser les photos et pièces justificatives, telles que :",
    },
  },
  footer: {
    contactUs: "Contactez-nous :",
    email: "Adresse courriel",
    warningByState: "Mise en garde faite par l'État.",
    termsAndConditions: "Modalités et conditions",
    privacyPolicy: "Politique de confidentialité",
    cookiePolicy: "Politique concernant les témoins ",
    copyright: "© 2019 Great American Insurance Company. Tous droits réservés.",
  },
  success: {
    successMessage: "Votre réclamation a été soumise avec succès !",
    claimRefNumber: "VOTRE NO DE RÉFÉRENCE DE RÉCLAMATION:",
    claimExpectancy:
      "Vous recevrez un suivi de votre réclamation d'ici le prochain jour ouvrable.",
    contactNumber: "COORDONNÉES SERVICE DES RÉCLAMATIONS:",
    submittingMessage: "Envoi de votre réclamation...",
  },
  validations: {
    required: "Champ obligatoire!",
    maxCharacters: "Doit contenir {count} caractères ou moins",
    email: "Email invalide",
    phone: "Numéro de téléphone invalide",
  },
};

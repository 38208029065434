import Vue from "vue";
import Vuetify from "vuetify";
import en from "@/locale/en";
import fr from "@/locale/fr";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, fr },
    current: window.navigator.language || "en",
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#00274e",
        secondary: "#ed0039",
        error: "#dc2020",
        "on-primary": "#FFF",
        "on-secondary": "#000",
        "on-background": "#000",
      },
    },
  },
  icons: {
    iconfont: "md",
  },
});

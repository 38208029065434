
import Vue from "vue";
import { LocaleMessage, LocaleMessages } from "vue-i18n";

class SelectOption {
  text: string | LocaleMessage | null;
  value: string;

  constructor(text: string | LocaleMessage | null, value: string) {
    this.text = text;
    this.value = value;
  }
}

export { SelectOption };

export default Vue.extend({
  data: () => ({
    drawer: false,
  }),
  computed: {
    locale: {
      get(): string {
        const l = this.$i18n.locale;
        for (const loc of this.availableLocales) {
          if (l.startsWith(loc)) {
            return loc;
          }
        }
        return this.$i18n.fallbackLocale as string;
      },
      set(newLocale: string): void {
        this.$vuetify.lang.current = newLocale;
        this.$i18n.locale = newLocale;
      },
    },
    availableLocales(): Array<string> {
      return this.$i18n.availableLocales;
    },
    localeSelections(): Array<SelectOption> {
      return this.availableLocales.map(
        (l) => new SelectOption(this.$t(`language.${l}.name`), l)
      );
    },
    selectedLocale(): SelectOption {
      return (
        this.localeSelections.find((l) => l.value === this.locale) ||
        new SelectOption(null, "")
      );
    },
  },
  mounted() {
    if (this.availableLocales.length > 0) {
      this.locale = this.availableLocales[0];
    }
  },
  methods: {
    getCountryIcon(locale: SelectOption): string | LocaleMessages | null {
      if (locale) {
        return this.$t(`language.${locale.value}.countryCode`);
      }
      return null;
    },
  },
});

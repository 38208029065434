import sesDigitalClaimsAxios from "./SesDigitalClaimsAxios";

const lossRegionEndpoint = "/lossRegions";

function getLossRegions() {
  try {
    return sesDigitalClaimsAxios
      .get(`${lossRegionEndpoint}`)
      .then((response) => response.data);
  } catch (e) {
    console.log(e.message);
  }
}

export { getLossRegions };

export default {
  requiredLabel: "Required",
  continueButton: "Continue",
  saveForLaterButton: "Save For Later",
  backButton: "Back",
  submitButton: "Submit",
  language: {
    en: {
      name: "English",
      countryCode: "usa",
    },
    fr: {
      name: "Français",
      countryCode: "fra",
    },
  },
  contactInfo: {
    instructions:
      "Please provide as much information as possible. * denotes mandatory field",
    title: "New Claim Information",
    customerTypeSelectTitle: "Are you a",
    lesseeCustomer: "Lessee/Customer",
    equipmentDealerVendor: "Equipment Dealer/Vendor",
    insuredLessor: "Insured/Lessor",
    yourInformationTitle: "Your Information",
    companyName: "Company Name",
    companyAddress: "Company Address",
    yourContactInformationTitle: "Your Contact Information",
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone #",
    extn: "Extn",
    emailAddress: "Email Address",
    leasingPolicyInformationTitle: "Leasing/Policy Information",
    lesseeCustomerName: "Lessee/Customer Name",
    leasingCompanyName: "Financial Institution or Leasing Company Name",
    contractNumber: "Lease or Contract Number",
    policyNumber: "Policy Number",
    lesseeAddress: "Lessee/Customer Address",
    lesseePhone: "Lessee/Customer Phone #",
    lesseeEmail: "Lessee/Customer Email",
    desc: {
      stepNumber: "Step 1",
      title: "Contact Info",
      description: "Please provide your contact information",
    },
  },
  equipmentAndLoss: {
    title: "Equipment Damaged",
    instructions: "Please provide as much information as possible",
    information:
      'Please click on the "Add Equipment" link below to add details on damaged equipment.\n' +
      "Click the check icon to save each piece of damaged equipment.",
    addEquipment: "Add Equipment",
    lossDetails: "Loss Details",
    lossOccurrenceDate: "Loss Occurrence Date",
    typeOfDamage: "Type of Damage",
    fire: "Fire",
    theftOfRobbery: "Theft or Robbery",
    water: "Water",
    accident: "Accident",
    other: "Other",

    nameOfFireDepartment: "Name of Fire Department",
    nameFirePlaceholder: "e.g., Hastings-on-Hudson NY Fire Department",
    reportOrIncidentNumber: "Report or Incident Number",
    reportIncidentPlaceholder: "Report/Incident#",

    uploadReport: "Upload Report",
    uploadButton: "BROWSE FOR A FILE",

    policeReportFiled: "Was a Police Report Filed",
    nameOfPoliceDepartment: "Name of Police Department",
    namePolicePlaceholder: "e.g., NYPD",

    sourceOfWaterDamage: "Source of Water Damage",
    sourceWaterPlaceholder: "e.g., sprinklers",
    equipmentExposedRain: "Was equipment left exposed to rain?",

    causeOfLoss: "Cause of Loss",
    causeLossPlaceholder: "Describe what happend (500 characters max)",
    locationOfLossDamage: "Location of Loss/Damage",
    locationLossDamagePlaceholder:
      "Indicate the physical location (e.g., address) of where the loss/damage happened",
    lossRegion: "Loss Region",
    lossRegionPlaceholder: "Select US State, Territory, or Canadian Province",

    otherInsuranceCoverage: "Other Insurance Coverage",
    ifAny: "(if any)",
    doYouHaveOtherInsurance: "Do you have other insurance?",
    yes: "Yes",
    no: "No",
    insurerName: "Insurer Name",
    policyNumber: "Policy#",
    phoneNumber: "Phone#",
    equipmentList: {
      equipmentType: "Equipment Type",
      make: "Make",
      model: "Model",
      serial: "Serial Number",
      year: "Year",
    },
    desc: {
      stepNumber: "Step 2",
      title: "Equipment & Loss Information",
      description: "Please provide details on the loss and affected equipment",
    },
  },
  fileUpload: {
    leaseAgreement: "Lease Agreement",
    originalPurchaseInvoice: "Original Purchase Invoice",
    prePostDamagePhotos: "Pre / post damage photos",
    dragAndDrop: "Drag to upload here or",
    uploadButton: "BROWSE FOR A FILE",
    lenderCopyLeaseAgreement:
      "*Your lender/funder will have a copy of your lease agreement and invoice",
    photosVideosDocuments: "Photos, Videos or Documents",
    validFileFormats:
      "Valid file formats: PNG, JPEG, GIF, TIFF, MOV, MP4, PDF, JPG, BMP",
    dragToUpload: "Drag to upload here",
    desc: {
      stepNumber: "Step 3",
      title: "File Upload",
      description: "Please upload supporting documents and photos, such as:",
    },
  },
  footer: {
    contactUs: "Contact Us:",
    email: "Email",
    warningByState: "Warning By State",
    termsAndConditions: "Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    cookiePolicy: "Cookie Policy",
    copyright: "© 2019 Great American Insurance Company. All Rights Reserved.",
  },
  success: {
    successMessage: "Your claim has been successfully submitted!",
    claimRefNumber: "YOUR CLAIM REFERENCE # :",
    claimExpectancy:
      "You will receive a follow up to your claim within 1 business day.",
    contactNumber: "CLAIMS CONTACT:",
    submittingMessage: "Submitting your claim...",
  },
  validations: {
    required: "Required field!",
    maxCharacters: "Must be {count} characters or less",
    email: "Invalid e-mail",
    phone: "Invalid Phone Number",
  },
};

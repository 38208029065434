import Vue from "vue";
import App from "@/App.vue";
import vuetify from "@/plugins/vuetify";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";

import CountryFlag from "vue-country-flag";
Vue.component("CountryFlag", CountryFlag);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import i18n from "@/i18n";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <v-container>
    <v-footer dark app padless>
      <v-btn
        v-show="current_step != 1"
        id="btnBack"
        tile
        color="#3f3f3f"
        x-large
        @click="$emit('onBack')"
      >
        <v-icon>mdi-arrow-left</v-icon>
        <span class="d-none d-md-block">{{ $t("backButton") }}</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="false"
        v-show="current_step != total_steps"
        id="btnSaveForLater"
        tile
        color="#3f3f3f"
        x-large
        @click="$emit('onSaveForLater')"
      >
        <v-icon>mdi-content-save</v-icon>
        <span class="d-none d-md-block">{{ $t("saveForLaterButton") }}</span>
      </v-btn>
      <v-btn
        v-show="current_step != total_steps"
        id="btnContinue"
        tile
        color="#5b7b92"
        x-large
        @click="$emit('onContinue')"
      >
        <span class="d-none d-md-block">{{ $t("continueButton") }}</span>
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn
        v-show="current_step == total_steps"
        id="btnSubmit"
        tile
        color="#5b7b92"
        x-large
        @click="$emit('onSubmit')"
        >{{ $t("submitButton") }}</v-btn
      >
    </v-footer>
  </v-container>
</template>

<script>
export default {
  props: {
    current_step: {
      type: Number,
      default: 1,
    },
    total_steps: {
      type: Number,
      default: 1,
    },
  },
};
</script>

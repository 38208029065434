
import Vue from "vue";
import steps from "@/steps/StepContainer.vue";
import LocaleSelector from "@/components/LocaleSelector.vue";

const GAI_URL = "https://www.greatamericaninsurancegroup.com";

export default Vue.extend({
  components: {
    LocaleSelector,
    steps,
  },
  data: () => ({
    drawer: false,
    gaiUrl: GAI_URL,
  }),
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
});

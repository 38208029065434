<template>
  <v-form ref="form" v-model="valid">
    <!-- Header -->
    <template>
      <div
        class="red--text text-right text-caption"
        v-text="$t('contactInfo.instructions')"
      />
      <div class="text-h4 font-weight-bold" v-text="$t('contactInfo.title')" />
    </template>

    <!-- Claimant Type Selector -->
    <div class="mt-5">
      <div class="text-h6" v-text="$t('contactInfo.customerTypeSelectTitle')" />
      <v-container>
        <v-radio-group v-model="claim.claimantTypeCode" row>
          <v-radio
            :label="$t('contactInfo.lesseeCustomer')"
            class="text-no-wrap"
            :value="ClaimantType.LESSEE"
          />
          <v-radio
            :label="$t('contactInfo.equipmentDealerVendor')"
            class="text-no-wrap"
            :value="ClaimantType.EQUIPMENT_VENDOR"
          />
          <v-radio
            :label="$t('contactInfo.insuredLessor')"
            class="text-no-wrap"
            :value="ClaimantType.LESSOR"
          />
        </v-radio-group>
      </v-container>
    </div>

    <!-- Your Information -->
    <template>
      <div
        class="text-h6 mb-2"
        v-text="$t('contactInfo.yourInformationTitle')"
      />
      <v-container>
        <v-row justify-sm="space-between" class="contentRows">
          <v-col cols="12">
            <label for="companyName">
              {{ $t("contactInfo.companyName") }}
              <span class="red--text"><strong> *</strong></span>
            </label>
            <v-text-field
              id="companyName"
              v-model="claim.companyName"
              maxlength="50"
              :placeholder="$t('contactInfo.companyName')"
              :rules="[
                validationRules.required(),
                validationRules.maxNCharacters(50),
              ]"
              counter="50"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row justify-sm="space-between" class="contentRows">
          <v-col cols="12">
            <label for="companyAddress">
              {{ $t("contactInfo.companyAddress") }}
              <span class="red--text"><strong> *</strong></span>
            </label>
            <v-text-field
              id="companyAddress"
              ref="companyAddress"
              v-model="claim.companyAddress"
              maxlength="100"
              :placeholder="$t('contactInfo.companyAddress')"
              :rules="[
                validationRules.required(),
                validationRules.maxNCharacters(100),
              ]"
              counter="100"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- Your Contact Information -->
    <template>
      <div
        class="text-h6 mb-2"
        v-text="$t('contactInfo.yourContactInformationTitle')"
      />
      <v-container>
        <v-row justify-sm="space-between" class="contentRows">
          <v-col cols="12" sm="6">
            <label for="firstName">
              {{ $t("contactInfo.firstName") }}
              <span class="red--text"><strong> *</strong></span>
            </label>
            <v-text-field
              id="firstName"
              v-model="claim.firstName"
              maxlength="50"
              :placeholder="$t('contactInfo.firstName')"
              :rules="[validationRules.required()]"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <label for="lastName">
              {{ $t("contactInfo.lastName") }}
              <span class="red--text"><strong> *</strong></span>
            </label>
            <v-text-field
              id="lastName"
              v-model="claim.lastName"
              maxlength="50"
              :placeholder="$t('contactInfo.lastName')"
              :rules="[validationRules.required()]"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify-sm="space-between" class="contentRows">
          <v-col cols="12" sm="6" class="d-flex">
            <div class="flex-grow-1">
              <label for="phoneNumber">
                {{ $t("contactInfo.phoneNumber") }}
                <span class="red--text"><strong> *</strong></span>
              </label>
              <v-text-field
                id="phoneNumber"
                v-model="claim.phoneNumber"
                v-mask="'(###) ###-####'"
                :placeholder="$t('contactInfo.phoneNumber')"
                :rules="[validationRules.required(), validationRules.phone()]"
                outlined
                dense
              />
            </div>
            <div
              class="flex-grow-0 ml-2"
              style="min-width: 80px; max-width: 80px"
            >
              <label for="phoneExtension">&nbsp;</label>
              <v-text-field
                id="phoneExtension"
                v-model="claim.phoneExtension"
                v-mask="'######'"
                type="number"
                outlined
                dense
                :placeholder="$t('contactInfo.extn')"
              >
                <template #prepend><span>-</span></template>
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <label for="emailAddress">
              {{ $t("contactInfo.emailAddress") }}
              <span class="red--text"><strong> *</strong></span>
            </label>
            <v-text-field
              id="emailAddress"
              ref="emailAddr"
              v-model="claim.emailAddress"
              :placeholder="$t('contactInfo.emailAddress')"
              :rules="[validationRules.required(), validationRules.email()]"
              outlined
              dense
              type="email"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- Leasing / Policy Information -->
    <template>
      <div class="text-h6 mb-2">
        <span v-text="$t('contactInfo.leasingPolicyInformationTitle')" />
        <span
          class="text-body-1 font-weight-light"
          v-text="' (' + $t('requiredLabel') + ')'"
        />
      </div>
      <v-container>
        <div id="typeFields">
          <!-- Lessee / Customer Name -->
          <v-row
            v-if="
              claim.claimantTypeCode === ClaimantType.EQUIPMENT_VENDOR ||
              claim.claimantTypeCode === ClaimantType.LESSOR
            "
            justify-sm="space-between"
            class="contentRows"
          >
            <v-col>
              <label for="vendor-lesseeCustomerName">
                {{ $t("contactInfo.lesseeCustomerName") }}
                <span class="red--text"><strong> *</strong></span>
              </label>
              <v-text-field
                id="vendor-lesseeCustomerName"
                v-model="claim.customerName"
                maxlength="50"
                :placeholder="$t('contactInfo.lesseeCustomerName')"
                :rules="
                  claim.claimantTypeCode === ClaimantType.EQUIPMENT_VENDOR ||
                  claim.claimantTypeCode === ClaimantType.LESSOR
                    ? [validationRules.required()]
                    : []
                "
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Company Name -->
          <v-row
            v-if="
              claim.claimantTypeCode === ClaimantType.LESSEE ||
              claim.claimantTypeCode === ClaimantType.EQUIPMENT_VENDOR
            "
            justify-sm="space-between"
            class="contentRows"
          >
            <v-col>
              <label for="lessee-leasingCompanyName">
                {{ $t("contactInfo.leasingCompanyName") }}
                <span class="red--text"><strong> *</strong></span>
              </label>
              <v-text-field
                id="lessee-leasingCompanyName"
                v-model="claim.leasingCompanyName"
                maxlength="50"
                :placeholder="$t('contactInfo.leasingCompanyName')"
                :rules="
                  claim.claimantTypeCode === ClaimantType.LESSEE ||
                  claim.claimantTypeCode === ClaimantType.EQUIPMENT_VENDOR
                    ? [validationRules.required()]
                    : []
                "
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Contract Number -->
          <v-row justify-sm="space-between" class="contentRows">
            <v-col>
              <label for="lessee-contractNumber">
                {{ $t("contactInfo.contractNumber") }}
                <span class="red--text"><strong> *</strong></span>
              </label>
              <v-text-field
                id="lessee-contractNumber"
                v-model="claim.contractNumber"
                maxlength="20"
                :placeholder="$t('contactInfo.contractNumber')"
                :rules="[validationRules.required()]"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Customer Address -->
          <v-row
            v-if="
              claim.claimantTypeCode === ClaimantType.EQUIPMENT_VENDOR ||
              claim.claimantTypeCode === ClaimantType.LESSOR
            "
            justify-sm="space-between"
            class="contentRows"
          >
            <v-col>
              <label for="lessor-lesseeAddress">{{
                $t("contactInfo.lesseeAddress")
              }}</label>
              <v-text-field
                id="lessor-lesseeAddress"
                ref="lessor-lesseeAddress"
                v-model="claim.vendorLesseeAddress"
                maxlength="100"
                :placeholder="$t('contactInfo.lesseeAddress')"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Customer Phone + Email -->
          <v-row
            v-if="
              claim.claimantTypeCode === ClaimantType.EQUIPMENT_VENDOR ||
              claim.claimantTypeCode === ClaimantType.LESSOR
            "
            justify-sm="space-between"
            class="contentRows"
          >
            <v-col cols="12" sm="6" class="d-flex">
              <div class="flex-grow-1">
                <label
                  for="lesseePhone"
                  v-text="$t('contactInfo.lesseePhone')"
                />
                <v-text-field
                  id="lesseePhone"
                  v-model="claim.vendorLesseePhoneNumber"
                  v-mask="'(###) ###-####'"
                  :placeholder="$t('contactInfo.lesseePhone')"
                  :rules="[validationRules.phone()]"
                  outlined
                  dense
                />
              </div>
              <div
                class="flex-grow-0 ml-2"
                style="min-width: 80px; max-width: 80px"
              >
                <label for="lesseePhoneExtension">&nbsp;</label>
                <v-text-field
                  id="lesseePhoneExtension"
                  v-model="claim.vendorLesseePhoneExtension"
                  v-mask="'######'"
                  type="number"
                  outlined
                  dense
                  :placeholder="$t('contactInfo.extn')"
                >
                  <template #prepend><span>-</span></template>
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <label for="vendor-lesseeEmail">{{
                $t("contactInfo.lesseeEmail")
              }}</label>
              <v-text-field
                id="vendor-lesseeEmail"
                v-model="claim.vendorLesseeEmail"
                :placeholder="$t('contactInfo.lesseeEmail')"
                :rules="[validationRules.email()]"
                outlined
                dense
                type="email"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Policy Number -->
          <v-row justify-sm="space-between" class="contentRows">
            <v-col cols="12" sm="6">
              <label for="lessee-policyNumber">{{
                $t("contactInfo.policyNumber")
              }}</label>
              <v-text-field
                id="lessee-policyNumber"
                v-model="claim.policyNumber"
                maxlength="20"
                :placeholder="$t('contactInfo.policyNumber')"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </template>

    <!-- Footer -->
    <stepControl
      :current_step="steps.current"
      :total_steps="steps.total"
      @onContinue="validateForm('onContinue')"
      @onSave="validateForm('onSave')"
      @onBack="$emit('onBack', claim)"
    />
  </v-form>
</template>

<script>
import stepControl from "@/components/stepControl";
import { ClaimantType } from "@/data/Claim";
import ValidationMixin from "@/mixins/ValidationMixin";

// The OutSystems application restricted addresses by country based on the language that was selected.
// If French was selected, only Canadian addresses would appear. Otherwise, only United States addresses would.
// If we are to implement that, this needs to be uncommented in order to update the restricted addresses by country.
//
// import { VueI18n } from "@/i18n.ts";

export default {
  name: "ContactInfo",
  components: {
    stepControl,
  },
  mixins: [ValidationMixin],
  props: {
    form_data: Object,
    steps: Object,
  },
  data: () => ({
    valid: false,
    isEmailValid: false,
    ClaimantType,
  }),
  computed: {
    claim: function () {
      return this.form_data;
    },
    localFormData: function () {
      return this.form_data;
    },
    localPhone: {
      get: function () {
        return this.form_data.phoneNumber;
      },
      set: function (newVal) {
        const phoneNum = this.removeNonPhoneNumbers(newVal);
        this.localFormData.phoneNumber = phoneNum;
        return newVal;
      },
    },
    localCustomerPhone: {
      get: function () {
        return this.localFormData.customerPhone;
      },
      set: function (newVal) {
        const phoneNum = this.removeNonPhoneNumbers(newVal);
        this.localFormData.customerPhone = phoneNum;
        return newVal;
      },
    },
  },
  // The OutSystems application restricted addresses by country based on the language that was selected.
  // If French was selected, only Canadian addresses would appear. Otherwise, only United States addresses would.
  // If we are to implement that, this needs to be uncommented in order to update the restricted addresses by country.
  //
  // watch: {
  //   "$i18n.locale": function () {
  //     this.wireGoogleAddressLookup("companyAddress", (address) => {this.claim.companyAddress = address;});
  //   },
  // }
  mounted() {
    this.wireGoogleAddressLookup("companyAddress", (address) => {
      this.claim.companyAddress = address;
    });

    this.wireGoogleAddressLookup("lessor-lesseeAddress", (address) => {
      this.claim.vendorLesseeAddress = address;
    });
  },
  methods: {
    removeNonPhoneNumbers: function (val) {
      return val.slice(1, 4) + val.slice(6, 9) + val.slice(10, 14);
    },
    validateForm: function (emitVal) {
      const validated = this.$refs.form.validate();
      if (validated) {
        this.$emit(emitVal, this.claim);
      } else {
        const isEmailValid = this.$refs["emailAddr"].valid;
        let targetClass = "";
        if (!isEmailValid) {
          this.$emit(
            "form-error",
            "Please provide a valid email address to continue. If you do not have an email address, please contact 1-800-833-3549 to initiate a claim."
          );
          targetClass = "#emailAddress";
        } else {
          this.$emit("form-error", "Please complete required fields");
          targetClass = ".v-messages.error--text:first-of-type";
        }
        this.$nextTick(() => {
          const el = this.$el.querySelector(targetClass);
          this.$vuetify.goTo(el);
          return;
        });
      }
    },
    wireGoogleAddressLookup(inputName, callback) {
      // The OutSystems application restricted addresses by country based on the language that was selected.
      // If French was selected, only Canadian addresses would appear. Otherwise, only United States addresses would.
      // If we are to implement that, this needs to be uncommented in order to update the restricted addresses by country.
      //
      // let options = {
      //   componentRestrictions: { country: "us" },
      // };
      //
      // if (this.$i18n.locale == "fr") {
      //   options = {
      //     componentRestrictions: { country: "ca" },
      //   };
      // }

      const options = {
        componentRestrictions: { country: ["us", "ca"] },
      };

      const input = document.getElementById(inputName);
      const autocomplete = new google.maps.places.Autocomplete(input, options);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        let addressComponents;

        if (place && place.address_components) {
          addressComponents = place.formatted_address;

          for (let i = 0; i < place.address_components.length; i++) {
            for (let j = 0; j < place.address_components[i].types.length; j++) {
              if (place.address_components[i].types[j] == "postal_code") {
                if (place.address_components[i].long_name) {
                  addressComponents = input.value.split(",");
                  addressComponents.splice(
                    addressComponents.length - 1,
                    0,
                    " " + place.address_components[i].long_name
                  );
                }
              }
            }
          }
        }

        if (addressComponents) {
          if (Array.isArray(addressComponents)) {
            callback(addressComponents.join());
          } else {
            callback(addressComponents);
          }
        }
      });
    },
  },
};
</script>

<style>
.contentRows > div {
  padding-top: 0;
  padding-bottom: 0;
}

/* Below needed to remove number input buttons */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

label {
  font-weight: 700;
}
</style>

const required = (val: unknown) => {
  return val !== undefined && val !== null && val !== "";
};

const maxNCharacters = (val: string | null, count: number) => {
  return (val || "").length <= count;
};

const email = (val: string | null) => {
  if (!val) {
    return true;
  }
  const pattern =
    /(^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)|^$/;
  return pattern.test(val);
};

const phone = (val: string | null) => {
  if (!val) {
    return true;
  }
  const pattern = /(\((\d{3})\)\s(\d{3})-(\d{4}))|^$/;
  return pattern.test(val);
};

export default {
  required,
  maxNCharacters,
  email,
  phone,
};
